export const FirebaseConfig = {
	"projectId": "trucks-jo",
	"appId": "1:82960708721:web:05d972d0287e2c79fbd7d0",
	"databaseURL": "https://trucks-jo-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "trucks-jo.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyA-593TcxcbSSMBho2jzZVmrcbtee0x5aA",
	"authDomain": "easynaqla.com",
	"messagingSenderId": "82960708721",
	"measurementId": "G-XCPT1C31RE"
};